// extracted by mini-css-extract-plugin
export var expandImage = "service-module--expandImage--72C0N";
export var service = "service-module--service--Uckwn";
export var serviceAbout = "service-module--serviceAbout---Axas";
export var serviceImg = "service-module--serviceImg--HeZS9";
export var serviceSocialIcon = "service-module--serviceSocialIcon--Oqk75";
export var serviceTitle = "service-module--serviceTitle--UggXJ";
export var serviceTopTextContainer = "service-module--serviceTopTextContainer--CTWwS";
export var servicesRoot = "service-module--servicesRoot--mHObg";
export var servicesRootContainer = "service-module--servicesRootContainer--Z7SKc";
export var title = "service-module--title--vnAR9";